<template>
  <div>
    <div
      v-for="model in modelsPermissions"
      :key="model.name"
      class="col-12 permissions-wrapper"
    >
      <h4 class="form-control-label">
        {{ $t(`COMMON.${model.name}`) }}
      </h4>
      <div class="permissions-wrapper-inner">
        <div
          class="permissions-wrapper-inner-item"
          v-for="permission in model.permissions"
          :key="permission.key"
        >
          <icon-check :checked="hasPermission(permission)" />
          {{ $t(`PERMISSIONS.${permission.key}`) }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import IconCheck from "@/components/IconCheck.vue";
import models from "@/constants/models";

export default {
  name: "role-permissions",

  components: { IconCheck },

  props: ["role"],

  data() {
    return { models: models, permissions: [] };
  },

  computed: {
    modelsPermissions() {
      const modelsPerms = [];
      const foundPerms = [];
      for (const model in models) {
        const permissions = [];
        for (const perm of this.permissions) {
          if (!foundPerms.includes(perm.key)) {
            let modelPermKey = `_${model}`;
            if (model == models.APP) {
              modelPermKey = `_${model}_`;
            }
            if (perm.key.includes(modelPermKey)) {
              permissions.push(perm);
              foundPerms.push(perm.key);
            }
          }
        }
        if (permissions.length > 0) {
          modelsPerms.push({ name: model, permissions: permissions });
        }
      }
      return modelsPerms;
    },
  },

  created() {
    this.getPermissions();
  },

  methods: {
    hasPermission(permission) {
      if (!this.role.permissions) {
        return false;
      }
      return !!this.role.permissions.find((perm) => {
        return perm.id === permission.id;
      });
    },
    async getPermissions() {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });

      try {
        await this.$store.dispatch("permissions/list", {
          sort: "name",
          page: {
            number: 1,
            size: 999999,
          },
          filter: {
            organization: this.role.organization?.id,
          },
        });
        this.permissions = await this.$store.getters["permissions/list"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }

      swal.close();
    },
  },

  mounted() {},

  watch: {
    role(role) {
      this.getPermissions();
    },
  },
};
</script>
