<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!user">
      <span class="loader"></span>
    </span>
    <user-form
      v-if="user"
      :loading="loading"
      :userData="user"
      :formErrors="formErrors"
      @userSubmitted="handleSubmit"
      @onCloseUserModal="onCloseUserModal"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import UserForm from "../partials/UserForm.vue";

export default {
  layout: "DashboardLayout",

  components: { UserForm },

  mixins: [alertLeave],

  props: {
    userId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      user: null,
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;

      try {
        await this.$store.dispatch("users/get", this.userId);
        this.user = { ...this.user, ...this.$store.getters["users/user"] };
        this.user.is_staff = !!this.user.is_staff;
        this.user.restrict_to_locations = !!this.user.restrict_to_locations;
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async handleSubmit(user) {
      this.loading = true;
      const userData = cloneDeep(user);

      try {
        await this.$store.dispatch("users/update", userData);
        this.$notify({
          type: "success",
          message: this.$t("USERS.USER_UPDATED"),
        });
        this.$emit("onViewUser", user, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },

    onCloseUserModal() {
      this.$emit("onViewUser", this.user);
    },
  },
};
</script>
