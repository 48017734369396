<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="page-wrapper-header-left">
          <div class="kw-back">
            <router-link
              v-if="$currentUserCan($permissions.PERM_VIEW_APP_ADMIN)"
              :to="{ name: 'Admin Page' }"
              v-slot="{ href, navigate }"
              custom
            >
              <a :href="href" @click="navigate">
                <i class="far fa-long-arrow-left"></i>
              </a>
            </router-link>
            <h3>{{ $t("USERS.USERS_LIST") }}</h3>
          </div>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <img src="/img/kw-home.svg" alt="icon" />
                <span>{{ $t("APPS.ADMIN_MODULE") }}</span>
              </li>
              <li>
                <span>{{ $t("USERS.USERS_LIST") }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="page-wrapper-header-right">
          <base-button
            class="kw-button add"
            icon
            size="sm"
            @click="openUserCreateModal"
            v-if="$currentUserCan($permissions.PERM_CREATE_USERS)"
          >
            <span class="btn-inner--icon">
              <i class="far fa-plus"></i>
            </span>
            <span class="btn-inner--text">{{ $t("USERS.ADD_USER") }}</span>
          </base-button>
        </div>
      </div>

      <user-list-table
        @onViewUser="openUserViewModal"
        @onEditUser="openUserEditModal"
        @onDeleteUser="deleteUser"
        @onAddUser="openUserCreateModal"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewUserModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewUserModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'USER_VIEW'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closeUserModal">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("COMMON.USER_DETAILS") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <wrapper-expand-button></wrapper-expand-button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-user-page
              v-if="openUser"
              :userId="openUser.id"
              @onEditUser="openUserEditModal"
              @onDeleteUser="deleteUser"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditUserModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditUserModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'USER'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="openUserViewModal(openUser)">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("USERS.EDIT_USER") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <wrapper-expand-button></wrapper-expand-button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-user-page
              v-if="openUser"
              :userId="openUser.id"
              @onViewUser="openUserViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddUserModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddUserModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'USER'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <button @click="closeUserModal">
                <i class="far fa-arrow-left"></i>
              </button>
              <h1>
                {{ $t("USERS.ADD_USER") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <wrapper-expand-button></wrapper-expand-button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-user-page
              @onViewUser="openUserViewModal"
              @onCloseUserModal="closeUserModal"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
// import NotificationSubscription from "@/components/NotificationSubscription.vue";
import UserListTable from "./partials/UserListTable.vue";
import EditUserPage from "./components/EditUserComponent.vue";
import AddUserPage from "./components/AddUserComponent.vue";
import ViewUserPage from "./components/ViewUserComponent.vue";
import WrapperExpandButton from "@/components/WrapperExpandButton";

export default {
  layout: "DashboardLayout",

  components: {
    WrapperExpandButton,
    UserListTable,
    // NotificationSubscription,
    EditUserPage,
    AddUserPage,
    ViewUserPage,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const userId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewUserModalOpened = false;
    let isEditUserModalOpened = false;
    let isAddUserModalOpened = false;
    let openUser = null;
    if (userId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewUserModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditUserModalOpened = true;
      }
      openUser = { id: userId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddUserModalOpened = true;
    }
    return {
      isViewUserModalOpened: isViewUserModalOpened,
      isEditUserModalOpened: isEditUserModalOpened,
      isAddUserModalOpened: isAddUserModalOpened,
      openUser: openUser,
      renderKey: 1,
    };
  },

  methods: {
    openUserCreateModal() {
      this.closeUserModal();
      this.isAddUserModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Users",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },
    openUserViewModal(user, reRender = false) {
      this.closeUserModal();
      this.openUser = user;
      this.isViewUserModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Users",
          query: { id: this.openUser.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },
    openUserEditModal(user) {
      this.closeUserModal();
      this.openUser = user;
      this.isEditUserModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Users",
          query: { id: this.openUser.id, action: QUERY_ACTIONS_EDIT },
        }).href
      );
    },
    closeUserModal() {
      this.isAddUserModalOpened = false;
      this.isViewUserModalOpened = false;
      this.isEditUserModalOpened = false;
      this.openUser = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Users",
          query: {},
        }).href
      );
    },

    async deleteUser(user) {
      const confirmation = await swal.fire({
        text: this.$t("USERS.DELETE_THIS_USER"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.CANCEL"),
        confirmButtonText: this.$t("COMMON.DELETE_ITEM"),
        confirmButtonClass: "btn btn-primary ",
        cancelButtonClass: "btn btn-warning",
      });

      let me = await this.$store.getters["profile/me"];

      if (user.id === me.id) {
        swal.fire({
          title: this.$t("USERS.CANNOT_SELF_DELETE"),
          buttonsStyling: false,
          showCancelButton: false,
          confirmButtonText: this.$t("COMMON.YES"),
          confirmButtonClass: "btn btn-primary",
        });
        return;
      }

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("users/destroy", user.id);
          this.renderKey++;
          this.closeUserModal();
          this.$notify({
            type: "success",
            message: this.$t("USERS.USER_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
