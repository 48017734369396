<template>
  <div class="container-fluid">
    <user-form
      :loading="loading"
      :userData="user"
      :formErrors="formErrors"
      @userSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
      @onCloseUserModal="onCloseUserModal"
    />
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultUser from "../defaultUser";
import UserForm from "../partials/UserForm.vue";
import { mapGetters } from "vuex";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    UserForm,
  },

  mixins: [alertLeave],

  computed: {
    ...mapGetters("organizations", {
      globalOrganization: "globalOrganization",
    }),
  },

  data() {
    return {
      user: cloneDeep(defaultUser),
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.user.organization = this.globalOrganization;
  },

  methods: {
    async handleSubmit(user) {
      this.loading = true;
      const userData = cloneDeep(user);

      try {
        await this.$store.dispatch("users/add", userData);
        this.$notify({
          type: "success",
          message: this.$t("USERS.USER_ADDED"),
        });
        const user = await this.$store.getters["users/user"];
        this.$emit("onViewUser", user, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },

    onCloseUserModal() {
      this.$emit("onCloseUserModal");
    },
  },
};
</script>
