<template>
  <div class="elite-tabs-wrapper-content">
    <h3>{{ user.name }}</h3>
    <dl class="row" v-for="role of user.roles" :key="role.id">
      <dt class="user-authorization-link">
        <router-link v-if="user.roles[0]" :to="$objectViewRoute(user.roles[0])">
          {{ role.name }}
        </router-link>
      </dt>
      <role-permissions :role="role" />
    </dl>
  </div>
</template>
<script>
import RolePermissions from "@/components/RolePermissions.vue";

export default {
  name: "user-view-authorizations",

  components: { RolePermissions },

  props: ["user"],

  mixins: [],

  data() {
    return {};
  },

  computed: {},

  methods: {},

  mounted() {},

  watch: {
    user(user) {},
  },
};
</script>
